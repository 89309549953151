<template>
  <section class="container">
    <div class="card table-list">
      <h3>{{ $t("transfers") }}</h3>
      <!-- 交易记录 -->
      <transfer-table
        :list="transactions"
        :isLoading="transactionLoading"
      ></transfer-table>
      <!-- 翻页 -->
      <pagination-view
        :total="total"
        :limit="limit"
        :page="page"
        @emitPage="getTransactions"
      ></pagination-view>
    </div>
  </section>
</template>

<script>
import paginationView from "base/pagination";
import transferTable from "base/transfer-table";
export default {
  data() {
    return {
      transactions: null,
      total: 0,
      limit: 20,
      page:1,
      transactionLoading: true,
    };
  },
  created() {
    this.getTransactions();
  },
  methods: {
    // 获取交易记录
    getTransactions(page=1) {
      this.page=page;
      this.transactionLoading = true;
      this.axios
        .get(
          this.domain +
            "v1/balances/transfer?page[size]=" +
            this.limit +
            "&filter[signed]=1&page[number]=" +
            page
        )
        .then((res) => {
          this.transactions = res.data.data;
          this.total = res.data.total;
          this.transactionLoading = false;
        })
        .catch(() => {
          console.log("Get transactions failed");
          this.transactions = null;
          this.transactionLoading = false;
        });
    },
  },
  components: {
    paginationView,
    transferTable,
  },
};
</script>
